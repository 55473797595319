import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public email: string = '';
  public password: string = '';
  public isLogInErrorMessage: boolean = false;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  logIn() {
    (this.authService.authCall(this.email, this.password)
    .subscribe({
      next: (res) => {        
        this.isLogInErrorMessage = false;
        this.router.navigate(['./create-advert'])
      },
      error: (err) => this.isLogInErrorMessage = true,
      complete: () => console.log('Auth was completed successfully')
    }));
  }

  createAccount(){
    this.router.navigate(['./create-user'])
  }
}
