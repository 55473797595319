import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptorInterceptor implements HttpInterceptor {

  constructor(private router: Router) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (localStorage.getItem('token')) {
      req = req.clone({
        setHeaders: {
          Authorization: localStorage.getItem('token')
        }
      })
    }

    return next.handle(req)
    .pipe(
          catchError((error: HttpErrorResponse) => {
             let errorMsg = '';
             if (error.error instanceof ErrorEvent) {
                console.error('This is client side error');
                errorMsg = `Error: ${error.error.message}`;
             } else {
                if(error.status == 401){
                  this.router.navigate(['./login']);
                }
                errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
             }
             return throwError(error);
          })
    )
}
}
