<div class=" content">
  <div class=" row">
    <div class=" col-md-8" style="top: 50%;
      left: 50%;
      width: 30em;
      height: 18em;
      margin-top: -9em;
      margin-left: -15em;
      /* border: 1px solid #666; */
      /* background-color: #b9faac; */
      position: fixed;">
      <div class=" card">
        <div class=" card-header" style="margin: auto;">
          <h5 class=" title">Log in</h5>
        </div>
        <div class=" card-body">
          <form>
            <div class=" row">
              <div class=" col-md-12">
                <div class=" form-group">
                  <input class=" form-control" placeholder="Email/User" type="text" [(ngModel)]="email" />
                </div>
              </div>
            </div>
            <div class=" row">
              <div class=" col-md-12">
                <div class=" form-group">
                  <input class=" form-control" placeholder="Password" type="password" [(ngModel)]="password" />
                </div>
              </div>
            </div>
            <div class=" row">
              <div class=" col-md-12">
                <div class=" form-group">
                  <label *ngIf="isLogInErrorMessage" style="padding-left: 5%; color:#e14eca">Email sau parola sunt incorrecte !</label>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class=" card-footer" style="margin: auto;">
          <button class=" btn btn-success animation-on-hover" type="submit" (click)="logIn()">LOG IN</button>
          <button class=" btn btn-success animation-on-hover" type="submit" (click)="createAccount()">CONT NOU</button>
        </div>
      </div>
    </div>
  </div>