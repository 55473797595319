<footer class=" footer">
  <div class=" container-fluid">
    <ul class=" nav">
      <li class=" nav-item">
        <a class=" nav-link" href="{{contactUrl}}">
          Contact
        </a>
      </li>
      <li class=" nav-item">
        <a class=" nav-link" href="{{facebookUrl}}" target="_blank">
          Facebook
        </a>
      </li>
      <li class=" nav-item">
        <a class=" nav-link" href="{{instagramUrl}}" target="_blank">
          Instagram
        </a>
      </li>
      <!-- <li class=" nav-item">
        <a class=" nav-link" href="http://localhost:4200">
          Famshare
        </a>
      </li> -->
      <!-- <li class=" nav-item">
        <a class=" nav-link" href="https://www.creative-tim.com/about-us?ref=bda-footer">
          About Us
        </a>
      </li>
      <li class=" nav-item">
        <a class=" nav-link" href="http://blog.creative-tim.com?ref=bda-footer"> Blog </a>
      </li> -->
    </ul>
    <div class=" copyright">
      &copy; 2023-{{ test | date: "yyyy" }} made with
      <i class=" tim-icons icon-heart-2"> </i> by
      <a href={{baseUrl}} target="_blank"> fete24 </a>
    </div>
  </div>
</footer>