import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GenericMessageResponse } from '../entities';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: boolean = false
  apiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) { }

  authCall(username: string, password: string) {
    return this.httpClient
      .post<any>(this.apiUrl + "/public/auth", { username, password })
      .pipe(
        map(userData => {
          let tokenStr = "Bearer " + userData.jwttoken;
          localStorage.setItem("token", tokenStr);
          return userData;
        })
      )
  }

  checkTokenCall(): Observable<GenericMessageResponse> {
    return this.httpClient
      .get<GenericMessageResponse>(this.apiUrl + "/private/auth/token");
  }

  logOut() {
    localStorage.removeItem("token");
  }
}
